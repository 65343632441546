
import { ref, watchEffect } from 'vue';
import axios from 'axios';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRoute } from 'vue-router';
gsap.registerPlugin(ScrollTrigger);

interface Projectitems {
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
    _embedded: {
        'wp:featuredmedia': {
            source_url: string;
        } [];
    };
    slug: string;
}

export default {
    data() {
        return {
            uniqueClassNames: ['class-1', 'class-2', 'class-3', 'class-4', 'class-5'],
        };
    },
    setup() {

        //TODO LOADING/RESET

        const route = useRoute();

        const projectitems = ref < Projectitems[] > ([]);

        watchEffect(() => {
            if (route.name === 'projects') {

                gsap.to('.projectloader', {
                    width: '0', // Animate to 75% initially
                    duration: 0.2, // Animate to 75% in 1 second
                });
                gsap.to('#morefollow', {
                    opacity: 0
                });

                gsap.to('.ani-top', {
                    y: -50,
                    opacity: 0,
                    duration: 0.3,
                    ease: 'slow',
                    stagger: 0.05,
                    overwrite: "auto",
                    onComplete: () => {
                        const randomPercentage = Math.floor(Math.random() * (80 - 50 + 1)) + 50; // Generates a random number between 50 and 80
                        //console.log(randomPercentage);
                        gsap.set('#projectgrid', { opacity: 0 });
                        gsap.to('.projectloader', {
                            width: `${randomPercentage}%`, // Animate to 75% initially
                            duration: 1, // Animate to 75% in 1 second
                        });
                        let apiUrl = '?_embed=&lang=en&per_page=100';
                        if (route.params.projectCategory == 'developments') {
                            apiUrl = '?_embed=&portfoliocategory=5&lang=en&per_page=100';
                        } else if (route.params.projectCategory == 'investments') {
                            apiUrl = '?_embed=&portfoliocategory=6&lang=en&per_page=100'
                        } else if (route.params.projectCategory == 'loans') {
                            apiUrl = '?_embed=&portfoliocategory=13&lang=en&per_page=100'
                        }

                        fetchData(apiUrl);
                    }
                });
            }
        });

        function fetchData(apiUrl: string) {
            axios.get < Projectitems[] > ('https://zincre.com/api/wp-json/wp/v2/cvr_portfolio' + apiUrl)
                .then(response => {
                    projectitems.value = response.data;
                    animateElements();
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Error fetching WordPress posts:', error);
                })
                .finally(() => {
                    //
                });
        }

        function animateElements() {
            setTimeout(() => {

                gsap.to('.projectloader', {
                    width: '100%',
                    duration: 0.5, // Animate to 100% in 0,5 second
                });

                const elements = document.querySelectorAll('.ani-top');
                gsap.set(elements, { opacity: 0, y: -50 });

                gsap.to(
                    '#projectgrid', {
                        opacity: 1,
                        onComplete: () => {
                            gsap.to(elements, {
                                y: 0,
                                opacity: 1,
                                duration: 0.5,
                                ease: 'slow',
                                stagger: 0.07
                            });
                            gsap.to('#morefollow', {
                                opacity: 1
                            });
                        }
                    }
                );
            }, 500);
        }

        // Function to decode HTML entities
        function decodeEntities(html: string): string {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = html;
            return textArea.value;
        }

        return {
            projectitems,
            decodeEntities
        };
    },
    methods: {
        animateProject(isHovered: boolean, event: Event) {
            const projectTitle = (event.currentTarget as Element).querySelector('.title');
            const projectSubTitle = (event.currentTarget as Element).querySelector('.subtitle');
            const projectImage = (event.currentTarget as Element).querySelector('.image');

            gsap.to(projectTitle, {
                y: isHovered ? 0 : 30,
                opacity: isHovered ? 1 : 0,
                delay: isHovered ? .1 : 0,
                duration: 0.5,
                ease: "power4"
            });

            gsap.to(projectSubTitle, {
                y: isHovered ? 0 : 30,
                opacity: isHovered ? 1 : 0,
                delay: isHovered ? 0 : 0,
                duration: 0.5,
                ease: "power4"
            });

            gsap.to(projectImage, {
                scale: isHovered ? 1.09 : 1,
                duration: 0.5,
                ease: "ease"
            });
        }
    },
    mounted() {
        document.body.classList.add('dark-header');
        const randomPercentage = Math.floor(Math.random() * (80 - 50 + 1)) + 50; // Generates a random number between 50 and 80
        //console.log(randomPercentage);
        gsap.to('.projectloader', {
            width: `${randomPercentage}%`, // Animate to 75% initially
            duration: 1, // Animate to 75% in 1 second
        });

    },
    unmounted() {
        //document.body.classList.remove('dark-header');
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    },
}
